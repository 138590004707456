
import { Component, Prop, Vue } from 'vue-property-decorator';
import { Inject } from 'inversify-props';
import {
    ChartOptions, ChartData, ChartDataSets, ChartElementsOptions,
} from 'chart.js';
import CustomGraph from '@/modules/common/components/ui-kit/custom-graph/graph.vue';
import CURRENT_HOTEL_GRAPH_COLOR_RGB from '@/modules/common/constants/current-hotel-graph-color-rgb.constant';
import CURRENT_HOTEL_GRAPH_COLOR from '@/modules/common/constants/current-hotel-graph-color.constant';
import { hexToRgb } from '@/modules/common/constants/default-graph-colors.constant';
import UserService, { UserServiceS } from '@/modules/user/user.service';
import UserSettingsService, { UserSettingsS } from '@/modules/user/user-settings.service';
import RankingHistoryTooltip from './ranking-history-tooltip.vue';
import IHistoryItem from '../interfaces/history-item.interface';
import ITooltipData from '../interfaces/tooltip-data.interface';
import RankingHistoryLegend from './ranking-history-legend.vue';

interface IDataSet extends ChartDataSets {
    isMainChart: boolean
    yAxisID: 'left-axis' | 'right-axis'
}

interface IMinMax {
    min: number
    max: number
}

@Component({
    components: { CustomGraph, RankingHistoryTooltip, RankingHistoryLegend },
})
export default class RankingHistoryChart extends Vue {
    @Inject(UserServiceS) private userService!: UserService;
    @Inject(UserSettingsS) private userSettingsService!: UserSettingsService;

    @Prop({
        required: true,
        type: Array,
    })
    data!: IHistoryItem[][];

    @Prop({
        default: false,
        type: Boolean,
    })
    isReverted!: boolean;

    @Prop({
        default: 10,
        type: Number,
    })
    maxRank!: number;

    @Prop({
        type: Object,
        default: () => ({}),
    })
    public hiddenGraphs!: Record<number, boolean>;

    private isSecondaryAxisShown = false;
    private clickedLabelIndex: number | null = null;
    private datasets: IDataSet[] = [] as IDataSet[];
    // Index of dataset for secondary chart
    private activeDatasetIndex: number | null = null;
    // Index of day for tooltip
    private activeLabelIndex: number | null = null;
    private secondaryThresholds: IMinMax | null = null;
    private primaryThresholds: IMinMax | null = null;
    private isTooltipFlipped: boolean = false;

    get chartData(): ChartData | null {
        const { hiddenGraphs } = this;
        const { currentHotelId } = this.userService;

        // Gradient for secondary graph
        const canvas = document.getElementById('line-chart') as HTMLCanvasElement | null;
        const ctx = canvas ? canvas.getContext('2d') as CanvasRenderingContext2D : null;

        const primaryDatasets: IDataSet[] = [];
        const secondaryDatasets: IDataSet[] = [];

        this.data.forEach((dayData, index) => {
            dayData.forEach((itemData, itemIndex) => {
                const opacity = this.activeDatasetIndex === itemIndex ? '1' : '0.2';
                const colors = this.userSettingsService.chartColors;

                if (colors === null) {
                    return;
                }

                const color = this.activeDatasetIndex === null
                    ? colors[itemIndex % colors.length]
                    : `rgba(${hexToRgb(colors[itemIndex % colors.length])}, ${opacity})`;

                const borderColor = itemData.id === currentHotelId
                    ? CURRENT_HOTEL_GRAPH_COLOR
                    : color;

                if (!primaryDatasets[itemIndex]) {
                    primaryDatasets[itemIndex] = {
                        label: itemData.name,
                        yAxisID: 'left-axis',
                        data: [],
                        isMainChart: true,
                        borderColor,
                        hidden: hiddenGraphs[itemData.id],
                    };
                }

                if (!secondaryDatasets[itemIndex]) {
                    secondaryDatasets[itemIndex] = {
                        label: itemData.name,
                        yAxisID: 'right-axis',
                        data: [],
                        fill: 'start',
                        isMainChart: false,
                        borderColor,
                        hidden: hiddenGraphs[itemData.id],
                    };

                    if (ctx) {
                        const currentColor = itemData.id === this.userService.currentHotelId
                            ? CURRENT_HOTEL_GRAPH_COLOR_RGB
                            : hexToRgb(colors[itemIndex % colors.length]);

                        const areaOpacity = 0.6;
                        const colorForArea = `rgba(${currentColor}, ${areaOpacity})`;
                        const grd: CanvasGradient = ctx.createLinearGradient(0, 0, 0, 230);
                        grd.addColorStop(0.9, 'rgba(255,255,255,0)');
                        grd.addColorStop(0.5, colorForArea);
                        grd.addColorStop(0, colorForArea);
                        secondaryDatasets[itemIndex].backgroundColor = grd;
                    }
                }

                if (this.isReverted) {
                    primaryDatasets[itemIndex].data![index] = itemData.secondary;
                    secondaryDatasets[itemIndex].data![index] = itemData.primary;
                } else {
                    primaryDatasets[itemIndex].data![index] = itemData.primary;
                    secondaryDatasets[itemIndex].data![index] = itemData.secondary;
                }

                secondaryDatasets[itemIndex].pointRadius = 0;
                secondaryDatasets[itemIndex].hoverRadius = 0;
                secondaryDatasets[itemIndex].borderWidth = 1;
            });
        });

        const shownDatasets = primaryDatasets;
        if (this.activeDatasetIndex !== null) {
            shownDatasets.push(secondaryDatasets[this.activeDatasetIndex]);
        }

        this.datasets = JSON.parse(JSON.stringify(shownDatasets));

        return {
            labels: [['-4M'], ['-3M'], ['-2M'], ['-1M'], ['Last', 'Update']],
            datasets: shownDatasets,
        };
    }

    get tooltipData(): ITooltipData {
        const defaultData = {
            title: '',
            color: '',
            values: {},
        };

        if (this.activeDatasetIndex === null || this.data.length === 0 || this.activeLabelIndex === null) {
            return defaultData;
        }

        const { label } = this.datasets[this.activeDatasetIndex];

        if (!label) {
            return defaultData;
        }

        const currentItem = this.data[this.activeLabelIndex].find(item => item.name === label);

        if (!currentItem) {
            return defaultData;
        }

        const colors = this.userSettingsService.chartColors;

        if (colors === null) {
            return defaultData;
        }

        let color = colors[this.activeDatasetIndex % colors.length];
        const { currentHotelId } = this.userService;
        if (currentHotelId && currentHotelId === currentItem.id) {
            color = CURRENT_HOTEL_GRAPH_COLOR;
        }

        return {
            title: label || '',
            color,
            values: {
                rank: String(currentItem.primary) || '---',
                maxRank: String(this.maxRank) || '---',
                reviews: String(currentItem.secondary || '---'),
            },
        };
    }

    get tooltipXOffset() {
        switch (this.activeLabelIndex) {
            case 0:
                return -30;
            case 4:
                return -90;
            default:
                return -60;
        }
    }

    get tooltipYOffset() {
        return this.isTooltipFlipped ? -138 : 30;
    }

    get options(): ChartOptions {
        if (!this.secondaryThresholds) {
            this.secondaryThresholds = { min: 0, max: 0 };

            const totalReviews = this.data.reduce((acc: number[], day: IHistoryItem[]) => (
                acc.concat(day.map(item => item.secondary || 0))
            ), []).filter(item => item);

            this.secondaryThresholds.min = Math.min(...totalReviews);
            this.secondaryThresholds.max = Math.max(...totalReviews);

            if (this.secondaryThresholds.min === Infinity) {
                this.secondaryThresholds.min = 0;
            }
            if (this.secondaryThresholds.max === -Infinity || this.secondaryThresholds.max === 0) {
                this.secondaryThresholds.max = 1000;
            }
        }

        if (!this.primaryThresholds) {
            this.primaryThresholds = { min: 0, max: 0 };

            const totalRanks = this.data.reduce((acc: number[], day: IHistoryItem[]) => (
                acc.concat(day.map(item => item.primary || 0))
            ), []).filter(item => item);

            this.primaryThresholds.min = Math.min(...totalRanks);
            this.primaryThresholds.max = Math.max(...totalRanks);

            if (this.primaryThresholds.min === Infinity) {
                this.primaryThresholds.min = 0;
            }
            if (this.primaryThresholds.max === -Infinity || this.primaryThresholds.max === 0) {
                this.primaryThresholds.max = 1000;
            }
        }

        const yAxisCommonOptions = {
            gridLines: {
                display: true,
                offsetGridLines: true,
                borderDash: [0, 4],
                color: '#ECF1F5',
                zeroLineWidth: 0,
            },
            ticks: {
                padding: 20,
                maxTicksLimit: 9,
            },
        };

        const primaryDiff = Math.abs(this.primaryThresholds.max - this.primaryThresholds.min);
        const secondaryDiff = Math.abs(this.secondaryThresholds.max - this.secondaryThresholds.min);

        const stepSizePrimary = primaryDiff / 5;
        const stepSizeSecondary = Math.ceil(secondaryDiff / 5);

        const primarySuggestedMin = this.primaryThresholds.min - primaryDiff * 0.2;
        const primarySuggestedMax = this.primaryThresholds.max + primaryDiff * 0.2;
        const secondarySuggestedMin = this.secondaryThresholds.min - secondaryDiff * 0.2;
        const secondarySuggestedMax = this.secondaryThresholds.max + secondaryDiff * 0.2;

        const suggestedLeft = {
            min: 0,
            max: 0,
        };

        const suggestedRight = {
            min: 0,
            max: 0,
        };

        if (this.isReverted) {
            suggestedLeft.min = secondarySuggestedMin > 0 ? secondarySuggestedMin : 0;
            suggestedLeft.max = secondarySuggestedMax;

            suggestedRight.min = primarySuggestedMin > 0 ? primarySuggestedMin : 0;
            suggestedRight.max = primarySuggestedMax;
        } else {
            suggestedLeft.min = primarySuggestedMin > 0 ? primarySuggestedMin : 0;
            suggestedLeft.max = primarySuggestedMax;

            suggestedRight.min = secondarySuggestedMin > 0 ? secondarySuggestedMin : 0;
            suggestedRight.max = secondarySuggestedMax;
        }

        const leftYAxis = {
            ...yAxisCommonOptions,
            id: 'left-axis',
            position: 'left',
            ticks: {
                ...yAxisCommonOptions.ticks,
                suggestedMin: suggestedLeft.min,
                suggestedMax: suggestedLeft.max,
            },
            offset: this.isReverted,
        };

        const rightYAxis = {
            ...yAxisCommonOptions,
            id: 'right-axis',
            position: 'right',
            ticks: {
                ...yAxisCommonOptions.ticks,
                suggestedMin: suggestedRight.min,
                suggestedMax: suggestedRight.max,
            },
            offset: !this.isReverted,
        };

        const yAxes = this.isSecondaryAxisShown
            ? [leftYAxis, rightYAxis]
            : [leftYAxis];

        return {
            onHover: this.handleChartHover,
            onClick: this.handleChartClick,
            maintainAspectRatio: false,
            layout: {
                padding: {
                    right: 30,
                },
            },
            elements: {
                line: {
                    backgroundColor: 'rgba(255, 255, 255, 0.1)',
                },
                point: {
                    radius: 3,
                    backgroundColor: 'white',
                    hoverRadius: 4,
                },
            },
            scales: {
                xAxes: [{
                    gridLines: {
                        display: true,
                        borderDash: [0, 1],
                        offsetGridLines: true,
                        color: '#ECF1F5',
                    },
                }],
                yAxes,
            },
            hover: {
                mode: 'nearest',
                intersect: false,
            },
            legend: {
                display: false,
            },
            animation: {
                duration: 0,
            },
        } as ChartOptions;
    }

    get wrapperRightPadding() {
        if (!this.secondaryThresholds || this.isSecondaryAxisShown) {
            return '0';
        }

        if (this.isReverted) {
            return '53.5px';
        }

        if (this.secondaryThresholds.max < 10) {
            return '47px';
        }

        if (this.secondaryThresholds.max < 100) {
            return '43.5px';
        }

        if (this.secondaryThresholds.max < 1000) {
            return '60px';
        }

        if (this.secondaryThresholds.max < 10000) {
            return '66.5px';
        }

        return '73px';
    }

    handleChartHover(e: MouseEvent, activeElements: any) {
        const canvas = e.target as HTMLCanvasElement;
        const [currentElement] = activeElements;

        if (canvas && currentElement) {
            // eslint-disable-next-line no-underscore-dangle
            this.isTooltipFlipped = currentElement._view.y < e.offsetY;
        }

        // Work only with 'nearest' hover mode
        if (!activeElements.length) {
            return;
        }

        // eslint-disable-next-line no-underscore-dangle
        const hoveredDatasetIndex = activeElements[0]._datasetIndex;

        if (hoveredDatasetIndex === null) {
            return;
        }

        // eslint-disable-next-line no-underscore-dangle
        this.activeLabelIndex = activeElements[0]._index;

        const { isMainChart } = this.datasets[hoveredDatasetIndex];

        if (!isMainChart) {
            return;
        }

        if (this.activeDatasetIndex === hoveredDatasetIndex) {
            return;
        }

        this.activeDatasetIndex = hoveredDatasetIndex;
    }

    handleChartClick(e: PointerEvent, activeElements: any) {
        if (!activeElements.length) {
            return;
        }

        // eslint-disable-next-line no-underscore-dangle
        this.clickedLabelIndex = activeElements[0]._index;

        this.$emit('setDay', this.clickedLabelIndex);
    }

    switchSecondaryYAxis(state: boolean) {
        if (!state) {
            this.activeDatasetIndex = null;
            this.activeLabelIndex = null;
        }

        this.isSecondaryAxisShown = state;
    }
}
